import Vue from 'vue'
import VueRouter from 'vue-router'
import config from '../../config'
import store from '../store'
import { VueEasyJwt } from "vue-easy-jwt"

const jwt = new VueEasyJwt()

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login/Login.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/paquetes',
    name: 'Paquetes',
    component: () => import(/* webpackChunkName: "about" */ '../views/Paquete/index.vue'),
    meta: {rutaProtegida: true, requireAuth: true }
  },
  {
    path: '/repartidor',
    name: 'Repartidor',
    component: () => import(/* webpackChunkName: "about" */ '../views/Repartidor/index.vue'),
    meta: {rutaProtegida: true, requireAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)){
    if (is_auth()){
      if (to.matched.some(record => record.meta.rutaProtegida)){
        try{
          let validoPeticion = await fetch(config.BASE_URL + `/user/rutaValida`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': JSON.parse(localStorage.getItem('tokenApi')).tokenJarvis
            },
            body: JSON.stringify({
              path: to.path,
              redirect: true
            })
          })
          let valido = await validoPeticion.json()
          if (valido.exito == 1){
            next()
          }else{
            next({path: '/'})
            store.dispatch('executeSnackBar', {msj: valido.message, color: 'error', activo: true})
          }
        }catch(error){
          next({path: '/'})
          store.dispatch('executeSnackBar', {msj: error.message, color: 'error', activo: true})
        }
      }else{
        next()
      }
    }else{
      next({ path: '/login' })
      store.dispatch('executeSnackBar', {msj: 'La sesión actual expiró', color: 'error', activo: true})
    }
  }else{
    next()
  }
})

function is_auth () {
  if (localStorage.getItem('tokenApi')){
    const token = JSON.parse(localStorage.getItem('tokenApi')).tokenJarvis
    return !jwt.isExpired(token)
  }else return false
}

export default router
