<template>
  <v-app>
    <NavigationDrawer
      v-if="$route.name != 'Login'"
    />
    <v-main :style="imagen">
      <v-container fluid>
        <v-scroll-x-transition hide-on-leave>
          <router-view/>
        </v-scroll-x-transition>
      </v-container>
    </v-main>
    <!-- Modal Carga -->
    <ModalCargaLineal />
    <!-- SnackBar Aviso -->
    <SnackBarAviso />
  </v-app>
</template>

<script>
  import router from './router'
  import NavigationDrawer from './components/generales/NavigationDrawer.vue'
  import ModalCargaLineal from './components/generales/ModalCargaLineal.vue'
  import SnackBarAviso from './components/generales/SnackBarAviso.vue'
  import ModalConfirmarAccion from './components/generales/ModalConfirmarAccion.vue'
export default {
  name: 'App',

  data: () => ({

  }),
  computed: {
    imagen (){
      return [ this.$route.name === 'Login' ? {
        'background-image' : `url(${require('../public/img/Login/Login.webp')})`,
        'background-position' : 'center',
        'background-repeat' : 'no-repeat',
        'height' : '500px',
        'background-size' : 'cover'
      } : {
        'height' : '100%'
      } ]
    },
  },
  components: {
    NavigationDrawer,
    ModalCargaLineal,
    SnackBarAviso,
    ModalConfirmarAccion
  },
  async beforeUpdate(){
    // asignar variables de la tienda
    this.initProject()
  },
  created () {
    //this.initProject()
  },
  methods: {
    async initProject(){
      if (localStorage.getItem('tokenApi')){
        // llenar la tienda
        await this.$store.dispatch('loadLocalStorage')
        // es token valido?
        let invalido = this.$jwt.isExpired(this.$store.state.tokenApi)
        if (invalido == true){
          // cierro el modal de carga por si acaso
          this.$store.state.modalCarga = false
          // quito el token de localStorage
          localStorage.removeItem('tokenApi')
          // redirijo al login e informo que se venció la sesión
          if (this.$route.path.toUpperCase() != '/LOGIN'){
            router.push({name: 'Login', params: {expirado: 1}})
          }
        }else{
          if (window.location.pathname.toUpperCase() == '/LOGIN'){
            router.push({name: 'Home'})
          }
        }
      }else{
        // enviar al usuario a loguearse
        if (this.$route.path.toUpperCase() != '/LOGIN'){
          router.push({name: 'Login'})
        }
      }
    }
  },
};
</script>

<style>
  #app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*color: #2c3e50;*/
}
.theme--dark.v-card {
  background-color: var(--v-secondary-base) !important;
  color: #fff;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: var(--v-secondary-base) !important;
  color: #fff;
}
.theme--dark.v-data-table {
    background-color: var(--v-secondary-base) !important;
    color: #fff;
}
.swal2-popup {
  font-family: Roboto, sans-serif !important;
}

.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot {
  min-height: 32px !important;
  font-size: 14px ;
}
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box.v-select--chips--small.v-input--dense .v-select__selections, .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small.v-input--dense .v-select__selections {
  min-height: 32px !important;
}
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner, .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-inner, .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer {
  margin-top: 4px !important;
}
.v-list, .v-list--dense .v-list-item .v-list-item__content, .v-list-item--dense .v-list-item__content {
  padding: 0px !important;
}
.v-list--dense .v-list-item, .v-list-item--dense {
  min-height: 30px !important;
}
.v-list-item__action {
  margin: 4px 8px 4px 0px !important;
}

.v-expansion-panel--active>.v-expansion-panel-header {
  min-height: 48px !important;
}

.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important; /* default: 10px */
}
.v-text-field--filled .v-text-field__prefix, .v-text-field--filled .v-text-field__suffix {
  max-height: 32px;
  margin-top: 0px !important;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  padding: 0 8px !important;
}
.cebra tr:nth-of-type(even) { /* con even empieza el priero con color mas claro y con odd empieza el segundo */
  background-color: rgba(0, 0, 0, .1);
}

.v-data-table tbody tr.v-data-table__selected {
  background: var(--v-rowsel-base) !important;
}

.v-chip .v-icon {
  font-size: 18px !important;
}

input[type=tel] {
  text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: right;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: right;
}

/* Estilos de los sweetalerts2 */
.swal2-popup {
  color: var(--v-swalText-base) !important;
  background: var(--v-swalBkg-base) !important;
}

/* boton confirmar */
.swal2-confirm {
  background: var(--v-success-base) !important;
}

/* boton cancelar */
.swal2-cancel {
  background: var(--v-error-base) !important;
}
</style>