<template>
  <v-dialog
    v-model="$store.state.modalAccion.activo"
    max-width="800px"
    persistent
  >
    <v-card flat max-width="800px">
      <v-card-title class="pa-0">
        <v-toolbar flat :color="$store.state.modalAccion.colorTitulo">
          <v-toolbar-title class="white--text">
            <v-icon class="mr-2" color="white">fas fa-exclamation-circle</v-icon>{{ $store.state.modalAccion.titulo }}
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <br/>
      <v-card-text class="text-center">
        <p style="font-size: 20px">{{ $store.state.modalAccion.texto }}</p>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pb-4">
        <v-btn :color="$store.state.modalAccion.colorAceptar" @click="emitirAceptar()">
          <v-icon>fas fa-check</v-icon> Aceptar
        </v-btn>
        <v-btn :color="$store.state.modalAccion.colorCancelar" @click="cerrarModalConfirmar()">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    emitirAceptar(){
      this.$emit('ejecutarAccion', true)
      this.$store.dispatch('executeModalAccion', {activo: false, titulo: '', texto: '', colorTitulo: '', colorAceptar: '', colorCancelar: ''})
    },
    cerrarModalConfirmar(){
      this.$emit('ejecutarAccion', false)
      this.$store.dispatch('executeModalAccion', {activo: false, titulo: '', texto: '', colorTitulo: '', colorAceptar: '', colorCancelar: ''})
    }
  },
}
</script>

<style>

</style>