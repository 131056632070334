import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()
import store from '../store'

export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getTokenState({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let invalido = jwt.isExpired(store.state.tokenApi)
          return resolve({
            resultado: 1,
            msj: 'OK',
            invalido: invalido
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getTokenState: ' + error.message,
            invalido: null
          })
        }
      })
    }
  }
}