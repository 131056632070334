import Vue from 'vue'
import Vuex from 'vuex'
import config from '../../config.js'
import { Encrypt, Decrypt } from '../util/aes.js'
import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()
Vue.use(Vuex)

// modulos
import usuarios from '../modules/usuarios'
import paquetes from '../modules/paquetes'
import repartidores from '../modules/repartidores.js'

export default new Vuex.Store({
  state: {
    tokenApi: '',
    respTokenApi: {},
    img_perfil: '',
    username: '',
    modalCarga: false,
    msjModalCarga: '',
    snackBar: {
      activo: false,
      color: '',
      msj: ''
    },
    modalAccion: {
      activo: false,
      titulo: '',
      texto: '',
      colorTitulo: '',
      colorAceptar: '',
      colorCancelar: ''
    }
  },
  mutations: {
    setTokenJarvis(state, payload){
      if (localStorage.getItem('tokenApi')){
        state.tokenApi = JSON.parse(localStorage.getItem('tokenApi')).tokenJarvis
      }else{
        state.tokenApi = ''
      }
    },
    setSnackBarAviso(state, payload){
      state.snackBar.msj = payload.msj
      state.snackBar.color = payload.color
      state.snackBar.activo = payload.activo
    },
    setModalDecision(state, payload){
      state.modalAccion.activo = payload.activo
      state.modalAccion.titulo = payload.titulo
      state.modalAccion.texto = payload.texto
      state.modalAccion.colorTitulo = payload.colorTitulo
      state.modalAccion.colorAceptar = payload.colorAceptar
      state.modalAccion.colorCancelar = payload.colorCancelar
    },
    setTokenApi(state, payload){
      state.respTokenApi = payload
    },
    setUsuario(state, payload){
      state.username = payload.toString().toUpperCase()
    },
    setImgPerfil(state, payload){
      state.img_perfil = payload
      localStorage.setItem('img_perfil', JSON.stringify(payload))
    }
  },
  actions: {
    async loadLocalStorage({commit}){
      commit('setTokenJarvis')
      if (localStorage.getItem('tokenApi')){
        commit('setUsuario', Decrypt(jwt.decodeToken(JSON.parse(localStorage.getItem('tokenApi')).tokenJarvis).credenciales.usuario))
      }
      if (localStorage.getItem('img_perfil')){
        commit('setImgPerfil', JSON.parse(localStorage.getItem('img_perfil')))
      }
    },
    async executeSnackBar({commit}, datos){
      commit('setSnackBarAviso', datos)
    },
    async executeModalAccion({commit}, datos){
      commit('setModalDecision', datos)
    },
    async executeLoginApi({commit, dispatch}, datos){
      let loginRes = {}
      try{
        // elimino la clave de la API de localStorage
        if (localStorage.getItem('tokenApi')){
          localStorage.removeItem('tokenApi')
        }
        // armo el body para el nuevo login
        let body = {
          usuario: Encrypt(datos.user.toString().toUpperCase()),
          clave: Encrypt(datos.password)
        }
        let loginPedido = await fetch(config.BASE_URL + '/user/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With'
          },
          body: JSON.stringify(body)
        })
        let login = await loginPedido.json()
        // tuve exito al hacer Login?
        if (parseInt(login.exito) === 1){
          // guardo el token en localstorage
          localStorage.setItem('tokenApi', JSON.stringify({tokenJarvis: login.long_token}))
          // guardo el token en el store del proyecto
          commit('setTokenJarvis')
          // asigno nombre de usuario
          commit('setUsuario', Decrypt(jwt.decodeToken(login.token).credenciales.usuario))
          // guardo usuario y foto de perfil
          await dispatch('getImgPerfil')
          loginRes.resultado = 1
          loginRes.msj = 'OK'
          commit('setTokenApi', loginRes)
        }else{
          loginRes.resultado = 0
          loginRes.msj = login.message
          commit('setTokenApi', loginRes)
        }
      }catch(error){
        loginRes.resultado = 0
        loginRes.msj = 'Ocurrio un problema al ejecutar el metodo executeLoginApi: ' + error.message
        commit('setTokenApi', loginRes)
      }
    },
    async getImgPerfil({ dispatch, commit, state }){
      await dispatch('getAllFiles', { path: '/imagenes_perfil/' + state.username.toString().toUpperCase(), raiz: 1 })
        .then(res => {
          if (res.size > 0) {
            commit('setImgPerfil', res.files[0].file)
          } else {
            commit('setImgPerfil', '')
          }
        })
        .catch(() => {
          commit('setImgPerfil', '')
        })
    },
    async getAllFiles({ state }, payload){
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/getAllFiles`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': state.tokenApi
            },
            body: JSON.stringify(payload)
          })

          const data = await res.json()

          if (data.exito === 1) {
            resolve(data)
          } else {
            reject(data)
          }
          
        } catch (error) {
          reject(error)
        }
      })
    }
  },
  modules: {
    usuarios,
    paquetes,
    repartidores
  }
})
